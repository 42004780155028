@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@font-face {
  font-family: 'SuzukiPROHeadline';
  src: url('~assets/fonts/SuzukiPROHeadline.eot');
  src: url('~assets/fonts/SuzukiPROHeadline.eot?#iefix')
      format('embedded-opentype'),
    url('~assets/fonts/SuzukiPROHeadline.svg#SuzukiPROHeadline') format('svg'),
    url('~assets/fonts/SuzukiPROHeadline.ttf') format('truetype'),
    url('~assets/fonts/SuzukiPROHeadline.woff') format('woff'),
    url('~assets/fonts/SuzukiPROHeadline.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SuzukiPRORegular';
  src: url('~assets/fonts/SuzukiPRORegular.eot');
  src: url('~assets/fonts/SuzukiPRORegular.eot?#iefix')
      format('embedded-opentype'),
    url('~assets/fonts/SuzukiPRORegular.svg#SuzukiPRORegular') format('svg'),
    url('~assets/fonts/SuzukiPRORegular.ttf') format('truetype'),
    url('~assets/fonts/SuzukiPRORegular.woff') format('woff'),
    url('~assets/fonts/SuzukiPRORegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SuzukiPROBold';
  src: url('~assets/fonts/SuzukiPROBold.eot');
  src: url('~assets/fonts/SuzukiPROBold.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/SuzukiPROBold.svg#SuzukiPROBold') format('svg'),
    url('~assets/fonts/SuzukiPROBold.ttf') format('truetype'),
    url('~assets/fonts/SuzukiPROBold.woff') format('woff'),
    url('~assets/fonts/SuzukiPROBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


body {
  // font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  overflow-y: scroll;
   -webkit-overflow-scrolling: touch;
}
h1,h2,h3,h4,h5,h6{
  font-family: SuzukiPROBold, 'Montserrat';
}

h1 {
  color: #001151;
  font-size: 42px;
  line-height: 42px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  // font-weight: 700;

  @media (max-width: $breakpoint-sm-max) {
    font-size: 30px;
    line-height: 30px;
  }
}

.q-btn {
  font-family: SuzukiPRORegular !important;
  font-weight: 400 !important;
}

.detail-module {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  h2.module {
    color: #0f2832;
    font-weight: 600;
    text-align: left;
    margin: 0 0 40px 0;
    font-size: 24px;
    line-height: 24px;

    &::after {
      content: '';
      display: block;
      height: 3px;
      width: 60px;
      background-color: $primary;
      margin-top: 18px;
    }
  }
}

h3 {
  color: #0f2832;
  font-weight: 600;
  margin: 0;
  font-size: 18px;
}

/**
    MAIN-CONTAINER
    Contenedor principal responsivo para
    componentes que nos son del ancho total
    de la pantalla, limitado a 1280px de ancho.
*/
.main-container {
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0px auto;

  @media screen and (max-width: $breakpoint-md) {
    padding: 0 20px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding: 0 10px;
  }
}

.ms-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
}

.q-btn.btn-suzuki {
  font-size: 12px;
  font-weight: 600;
  color: $primary;
  opacity: 1;
  // padding: 10px 25px;
  text-transform: inherit;
  border-radius: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  line-height: 15px;
  height: 40px;
}
.q-btn.btn-outline {
  font-size: 12px;
  font-weight: 600;
  color: $primary;
  opacity: 1;
  padding: 10px 10px;
  text-transform: inherit;
  border-radius: 25px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0);
  line-height: 15px;
  height: 50px;
  .q-btn__content {
    padding: 0 10px;
  }
  i {
    font-size: 12px;
    margin-top: 2px;
  }
}
.q-input.input-suzuki {
  .q-field__bottom {
    padding: 8px 12px 0 4px;
  }
  .q-field__control {
    height: 50px;
    border-radius: 3pt;
    border-width: 1pt;
  }
  .q-field__label {
    color: $accent;
    opacity: 1;
    font-size: 14pt;
    font-family: SuzukiPRORegular;
    font-weight: 600;
    line-height: 18px;
    top: -13px;
    left: -9px;
    padding-bottom: 10px;
  }
  .q-placeholder {
    color: rgba(159, 169, 173, 1);
    opacity: 1;
    font-family: SuzukiPRORegular;
    font-size: 12px;
    padding-top: 8px;
  }
}

.q-input.input-contact, .q-textarea.input-contact, .q-select.input-contact {
  .q-field__bottom {
    padding: 8px 12px 0 4px;
  }
  .q-field__control {
    &::before {
      border: 1px solid #c0c0c0;
      border-radius: 0;
    }
  }
  .q-field__label {
    color: #000;
    opacity: 1;
    font-size: 20px;
    font-family: SuzukiPRORegular;
    font-weight: 400;
    line-height: 18px;
    top: -13px;
    left: -9px;
    padding-bottom: 10px;
  }
  .q-placeholder {
    color: #0F2832;
    opacity: 1;
    font-family: SuzukiPRORegular;
    font-size: 14px;
    padding-top: 8px;
  }
}

.q-select.input-promotion {
  .q-field__bottom {
    padding: 8px 12px 0 4px;
  }
  .q-field__control {
    &::before {
      border: 1px solid rgba(0, 0, 0, 0.24);;
    }
  }
  .q-field__label {
    color: $accent;
    opacity: 1;
    font-size: 14pt;
    font-weight: 600;
    line-height: 18px;
    top: -13px;
    left: -9px;
    padding-bottom: 10px;
  }
  .q-field--float .q-field__label {
    transform: initial;
  }
  .q-placeholder {
    color: #0F2832;
    opacity: 1;
    font-size: 12px;
    padding-top: 8px;
  }
}

.q-select.costs {
  .q-field__native {
    color: #000;
    opacity: 1;
    font-size: 16px;
  }
}

.q-input.input-contact {
  .q-field__control {
    height: 50px;
    border-radius: 3pt;
    border-width: 1pt;
  }
}

.q-select.input-contact, .q-select.input-promotion {
  .q-field__control {
    height: 50px;
    min-height: 50px;
    border-radius: 3px;
    border-width: 1px;

    &-container {
      padding-top: 8px;

      .q-field__native {
        color: rgba(159, 169, 173, 1);
        font-size: 12px;
      }
    }

    .q-field__marginal {
      height: 50px;
    }
  }
}

.q-input.input-secondary {
  .q-field__control {
    background-color: #fff;
    height: 40px;
  }
  .q-field__native {
    color: #9fa9ad;
    font-size: 12px;
  }
}
.q-checkbox {
  vertical-align: middle;
  &__bg {
    border-radius: 0;
  }
}
.q-dialog {
  &__inner {
    &--minimized {
      padding: 0;
      > div {
        border-radius: 0;
        max-height: 100vh;
      }
    }
  }
}
body.platform-ios,
body.platform-android:not(.cordova) {
  .q-dialog__inner--minimized > div {
    max-height: 100vh;
  }
}
.q-carousel.banner-home {
  height: 200px;
  .q-carousel__slide {
    height: 200px;
    padding: 0 35px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
  }
  .q-carousel__prev-arrow,
  .q-carousel__next-arrow {
    .q-icon {
      font-size: 20px;
    }
  }
}

.ms-features-carousel {
  overflow: hidden;

  .q-panel {
    overflow: hidden;
  }
}

.menu-suzuki-item:hover {
  // background-color: $grey-1;
  .q-focus-helper {
    opacity: 0.08 !important;
  }
}

.input-desktop.q-field .q-field__inner .q-field__control::before {
  border-bottom: 1px solid $primary;
}

// Autocomplete de google
.pac-container {
  z-index: 9000 !important;
}

.ios-padding {
  padding-top: 60px;
  padding-bottom: 100px;
}

.text-suzuki {
  color: $suzuki !important;
}
.bg-suzuki {
  background: $suzuki !important;
}


a.btn-suzuki,
a.btn-suzuki-underline {
  border: 2px solid $primary;
  padding: 0;
  background-color: #fff;
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 280px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: $breakpoint-sm-max) {
    max-width: initial;
    width: calc(50% - 20px);
  }

  &:hover {
    > span {
      background-color: $primary;

      &.label {
        color: #fff;
      }

      &.icon-wrapper {
        background-color: $primary;

        img {
          filter: grayscale(1) invert(1);
        }

        .icon {
          fill: #fff;
        }

        .icon-X_logo_2023 {
          color: #fff;
        }
      }
    }
  }

  > span {
    display: block;

    &.label {
      transition: background-color 0;
      flex: 1 0 auto;
      font-size: 14px;
      line-height: 38px;
      color: $primary;
      font-family: 'SuzukiPROBold', 'Roboto';
      padding-left: 10px;
      text-align: left;
    }

    &.icon-wrapper {
      transition: background-color 0;
      transition-delay: 50ms;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap:nowrap;
      padding: 0 10px;

      .icon {
        fill: $primary;
      }

      .icon-X_logo_2023 {
        color: $primary;
      }
    }
  }
}

a.btn-suzuki-underline {
  border: none;

  &:hover {
    > span {
      background-color: #fff;

      &.label {
        border-bottom: 2px solid $primary;
        color: $primary;
      }

      &.icon-wrapper {
        background-color: $primary;

        .icon {
          fill: #fff;
        }
      }
    }
  }

  > span {
    &.label {
      border-bottom: 2px solid #d9d8d9;
    }

    &.icon-wrapper {
      border-bottom: 2px solid $primary;
    }
  }
}
