@font-face {
  font-family: 'SuzukiPROHeadline';
  src: url("~assets/fonts/SuzukiPROHeadline.eot");
  src: url("~assets/fonts/SuzukiPROHeadline.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/SuzukiPROHeadline.svg#SuzukiPROHeadline") format("svg"), url("~assets/fonts/SuzukiPROHeadline.ttf") format("truetype"), url("~assets/fonts/SuzukiPROHeadline.woff") format("woff"), url("~assets/fonts/SuzukiPROHeadline.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SuzukiPRORegular';
  src: url("~assets/fonts/SuzukiPRORegular.eot");
  src: url("~assets/fonts/SuzukiPRORegular.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/SuzukiPRORegular.svg#SuzukiPRORegular") format("svg"), url("~assets/fonts/SuzukiPRORegular.ttf") format("truetype"), url("~assets/fonts/SuzukiPRORegular.woff") format("woff"), url("~assets/fonts/SuzukiPRORegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SuzukiPROBold';
  src: url("~assets/fonts/SuzukiPROBold.eot");
  src: url("~assets/fonts/SuzukiPROBold.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/SuzukiPROBold.svg#SuzukiPROBold") format("svg"), url("~assets/fonts/SuzukiPROBold.ttf") format("truetype"), url("~assets/fonts/SuzukiPROBold.woff") format("woff"), url("~assets/fonts/SuzukiPROBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

h1, h2, h3, h4, h5, h6 {
  font-family: SuzukiPROBold, 'Montserrat'; }

h1 {
  color: #001151;
  font-size: 42px;
  line-height: 42px;
  text-transform: uppercase;
  letter-spacing: 0.5px; }
  @media (max-width: 1023px) {
    h1 {
      font-size: 30px;
      line-height: 30px; } }

.q-btn {
  font-family: SuzukiPRORegular !important;
  font-weight: 400 !important; }

.detail-module {
  width: 100%;
  max-width: 100%;
  overflow: hidden; }
  .detail-module h2.module {
    color: #0f2832;
    font-weight: 600;
    text-align: left;
    margin: 0 0 40px 0;
    font-size: 24px;
    line-height: 24px; }
    .detail-module h2.module::after {
      content: '';
      display: block;
      height: 3px;
      width: 60px;
      background-color: #001151;
      margin-top: 18px; }

h3 {
  color: #0f2832;
  font-weight: 600;
  margin: 0;
  font-size: 18px; }

/**
    MAIN-CONTAINER
    Contenedor principal responsivo para
    componentes que nos son del ancho total
    de la pantalla, limitado a 1280px de ancho.
*/
.main-container {
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0px auto; }
  @media screen and (max-width: 1439px) {
    .main-container {
      padding: 0 20px; } }
  @media screen and (max-width: 599px) {
    .main-container {
      padding: 0 10px; } }

.ms-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16); }

.q-btn.btn-suzuki {
  font-size: 12px;
  font-weight: 600;
  color: #001151;
  opacity: 1;
  text-transform: inherit;
  border-radius: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  line-height: 15px;
  height: 40px; }

.q-btn.btn-outline {
  font-size: 12px;
  font-weight: 600;
  color: #001151;
  opacity: 1;
  padding: 10px 10px;
  text-transform: inherit;
  border-radius: 25px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0);
  line-height: 15px;
  height: 50px; }
  .q-btn.btn-outline .q-btn__content {
    padding: 0 10px; }
  .q-btn.btn-outline i {
    font-size: 12px;
    margin-top: 2px; }

.q-input.input-suzuki .q-field__bottom {
  padding: 8px 12px 0 4px; }

.q-input.input-suzuki .q-field__control {
  height: 50px;
  border-radius: 3pt;
  border-width: 1pt; }

.q-input.input-suzuki .q-field__label {
  color: #004081;
  opacity: 1;
  font-size: 14pt;
  font-family: SuzukiPRORegular;
  font-weight: 600;
  line-height: 18px;
  top: -13px;
  left: -9px;
  padding-bottom: 10px; }

.q-input.input-suzuki .q-placeholder {
  color: #9fa9ad;
  opacity: 1;
  font-family: SuzukiPRORegular;
  font-size: 12px;
  padding-top: 8px; }

.q-input.input-contact .q-field__bottom, .q-textarea.input-contact .q-field__bottom, .q-select.input-contact .q-field__bottom {
  padding: 8px 12px 0 4px; }

.q-input.input-contact .q-field__control::before, .q-textarea.input-contact .q-field__control::before, .q-select.input-contact .q-field__control::before {
  border: 1px solid #c0c0c0;
  border-radius: 0; }

.q-input.input-contact .q-field__label, .q-textarea.input-contact .q-field__label, .q-select.input-contact .q-field__label {
  color: #000;
  opacity: 1;
  font-size: 20px;
  font-family: SuzukiPRORegular;
  font-weight: 400;
  line-height: 18px;
  top: -13px;
  left: -9px;
  padding-bottom: 10px; }

.q-input.input-contact .q-placeholder, .q-textarea.input-contact .q-placeholder, .q-select.input-contact .q-placeholder {
  color: #0F2832;
  opacity: 1;
  font-family: SuzukiPRORegular;
  font-size: 14px;
  padding-top: 8px; }

.q-select.input-promotion .q-field__bottom {
  padding: 8px 12px 0 4px; }

.q-select.input-promotion .q-field__control::before {
  border: 1px solid rgba(0, 0, 0, 0.24); }

.q-select.input-promotion .q-field__label {
  color: #004081;
  opacity: 1;
  font-size: 14pt;
  font-weight: 600;
  line-height: 18px;
  top: -13px;
  left: -9px;
  padding-bottom: 10px; }

.q-select.input-promotion .q-field--float .q-field__label {
  transform: initial; }

.q-select.input-promotion .q-placeholder {
  color: #0F2832;
  opacity: 1;
  font-size: 12px;
  padding-top: 8px; }

.q-select.costs .q-field__native {
  color: #000;
  opacity: 1;
  font-size: 16px; }

.q-input.input-contact .q-field__control {
  height: 50px;
  border-radius: 3pt;
  border-width: 1pt; }

.q-select.input-contact .q-field__control, .q-select.input-promotion .q-field__control {
  height: 50px;
  min-height: 50px;
  border-radius: 3px;
  border-width: 1px; }
  .q-select.input-contact .q-field__control-container, .q-select.input-promotion .q-field__control-container {
    padding-top: 8px; }
    .q-select.input-contact .q-field__control-container .q-field__native, .q-select.input-promotion .q-field__control-container .q-field__native {
      color: #9fa9ad;
      font-size: 12px; }
  .q-select.input-contact .q-field__control .q-field__marginal, .q-select.input-promotion .q-field__control .q-field__marginal {
    height: 50px; }

.q-input.input-secondary .q-field__control {
  background-color: #fff;
  height: 40px; }

.q-input.input-secondary .q-field__native {
  color: #9fa9ad;
  font-size: 12px; }

.q-checkbox {
  vertical-align: middle; }
  .q-checkbox__bg {
    border-radius: 0; }

.q-dialog__inner--minimized {
  padding: 0; }
  .q-dialog__inner--minimized > div {
    border-radius: 0;
    max-height: 100vh; }

body.platform-ios .q-dialog__inner--minimized > div,
body.platform-android:not(.cordova) .q-dialog__inner--minimized > div {
  max-height: 100vh; }

.q-carousel.banner-home {
  height: 200px; }
  .q-carousel.banner-home .q-carousel__slide {
    height: 200px;
    padding: 0 35px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center; }
  .q-carousel.banner-home .q-carousel__prev-arrow .q-icon,
  .q-carousel.banner-home .q-carousel__next-arrow .q-icon {
    font-size: 20px; }

.ms-features-carousel {
  overflow: hidden; }
  .ms-features-carousel .q-panel {
    overflow: hidden; }

.menu-suzuki-item:hover .q-focus-helper {
  opacity: 0.08 !important; }

.input-desktop.q-field .q-field__inner .q-field__control::before {
  border-bottom: 1px solid #001151; }

.pac-container {
  z-index: 9000 !important; }

.ios-padding {
  padding-top: 60px;
  padding-bottom: 100px; }

.text-suzuki {
  color: #001151 !important; }

.bg-suzuki {
  background: #001151 !important; }

a.btn-suzuki,
a.btn-suzuki-underline {
  border: 2px solid #001151;
  padding: 0;
  background-color: #fff;
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 280px;
  cursor: pointer;
  text-decoration: none; }
  @media (max-width: 1023px) {
    a.btn-suzuki,
    a.btn-suzuki-underline {
      max-width: initial;
      width: calc(50% - 20px); } }
  a.btn-suzuki:hover > span,
  a.btn-suzuki-underline:hover > span {
    background-color: #001151; }
    a.btn-suzuki:hover > span.label,
    a.btn-suzuki-underline:hover > span.label {
      color: #fff; }
    a.btn-suzuki:hover > span.icon-wrapper,
    a.btn-suzuki-underline:hover > span.icon-wrapper {
      background-color: #001151; }
      a.btn-suzuki:hover > span.icon-wrapper img,
      a.btn-suzuki-underline:hover > span.icon-wrapper img {
        filter: grayscale(1) invert(1); }
      a.btn-suzuki:hover > span.icon-wrapper .icon,
      a.btn-suzuki-underline:hover > span.icon-wrapper .icon {
        fill: #fff; }
      a.btn-suzuki:hover > span.icon-wrapper .icon-X_logo_2023,
      a.btn-suzuki-underline:hover > span.icon-wrapper .icon-X_logo_2023 {
        color: #fff; }
  a.btn-suzuki > span,
  a.btn-suzuki-underline > span {
    display: block; }
    a.btn-suzuki > span.label,
    a.btn-suzuki-underline > span.label {
      transition: background-color 0;
      flex: 1 0 auto;
      font-size: 14px;
      line-height: 38px;
      color: #001151;
      font-family: 'SuzukiPROBold', 'Roboto';
      padding-left: 10px;
      text-align: left; }
    a.btn-suzuki > span.icon-wrapper,
    a.btn-suzuki-underline > span.icon-wrapper {
      transition: background-color 0;
      transition-delay: 50ms;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0 10px; }
      a.btn-suzuki > span.icon-wrapper .icon,
      a.btn-suzuki-underline > span.icon-wrapper .icon {
        fill: #001151; }
      a.btn-suzuki > span.icon-wrapper .icon-X_logo_2023,
      a.btn-suzuki-underline > span.icon-wrapper .icon-X_logo_2023 {
        color: #001151; }

a.btn-suzuki-underline {
  border: none; }
  a.btn-suzuki-underline:hover > span {
    background-color: #fff; }
    a.btn-suzuki-underline:hover > span.label {
      border-bottom: 2px solid #001151;
      color: #001151; }
    a.btn-suzuki-underline:hover > span.icon-wrapper {
      background-color: #001151; }
      a.btn-suzuki-underline:hover > span.icon-wrapper .icon {
        fill: #fff; }
  a.btn-suzuki-underline > span.label {
    border-bottom: 2px solid #d9d8d9; }
  a.btn-suzuki-underline > span.icon-wrapper {
    border-bottom: 2px solid #001151; }
